@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

@font-face {
  font-family: 'RogueLand';
  src: local('RogueLand'),
    url('../../../fonts/NCS Rogueland.ttf') format('truetype');
}

.table-templates .ant-table-thead .ant-table-cell {
  background-color: #ede7f6;
  font-weight: bold;
}

.text-white {
  color: #fff !important;
}
