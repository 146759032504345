@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

@font-face {
  font-family: 'RogueLand';
  src: local('RogueLand'),
    url('../../../fonts/NCS Rogueland.ttf') format('truetype');
}

.site-layout {
  margin-top: 50px;
}

#basic-layout {
  .logo {
    float: left;
    margin-left: 10px;
    margin-bottom: 0px;
    width: 80px;
    background: rgba(255, 255, 255, 0.3);
    img {
      width: 40px;
      height: 40px;
      margin-left: 15px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .menu-header {
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 2px 8px #d4d4d4 !important;
  }
  .site-layout-background {
    background: #fff;

    .menu-item-right {
      float: right;
    }
  }
  .menu-button {
    width: 15px;
    float: left;
    margin-left: 25px;
    font-size: 20px;
    z-index: 5;
    .menu-button--icon {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: rgb(158, 158, 158);
      }
    }
  }
}
.company-create-card {
  .ant-card-head-title {
    padding: 8px 0 !important;
  }
}
.company-profile--card__header {
  margin: 0;
  font-size: 20px;
  text-align: center;
}
.company-create-form-button {
  display: flex;
  justify-content: center;
  .ant-form-item-control-input-content {
    display: flex !important;
    justify-content: center !important;
  }
}

.responsive-sider {
  position: absolute !important ;
  height: 100vh;
  z-index: 1;
  box-shadow: 1px 4px 11px #d4d4d4 !important;
}

@media only screen and (min-width: 768px) {
  .responsive-sider {
    position: relative !important ;
    height: 100vh;
    z-index: 1;
    box-shadow: 1px 4px 11px #d4d4d4 !important;
  }
}

.hideOnDesktop {
  display: block;
}
@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

.hidden-row {
  display: none;
}

.main-container {
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
}

.clickable-row {
  cursor: pointer;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.download-contact-button {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.login-logo-section {
  font-size: 50px;
  font-family: 'RogueLand';
  margin-top: 50px;
  color: #5b2f16;
  float: left;

  .login-logo {
    width: 120px;
  }
}

.login-logo-section::first-letter {
  color: #e8e51b;
}

.logo-last-item::first-letter {
  color: #000;
}

.login-logo-section-mobile {
  display: none;
  font-size: 50px;
  font-family: 'RogueLand';
  margin-top: 50px;
  display: inline;
  color: #5b2f16;

  .login-logo {
    width: 60px;
  }
}

@media only screen and (max-width: 1280px) {
  .login-logo-section {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .login-logo-section {
    font-size: 40px;
    font-family: 'RogueLand';
    margin-top: 50px;

    .login-logo {
      width: 120px;
    }
  }
}
