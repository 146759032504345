.background-yellow {
  background-color: #fcf4a3;
}
.background-purple {
  background-color: #673ab7 !important;
}
.background-purple-light {
  background-color: #ede7f6 !important;
}

.btn-input {
  margin: 0 0 10px 14px;
}

.btn-col {
  text-align: center;
}

.checkbox-item {
  margin-bottom: 5px;
}

.input-card-container {
  background-color: #ede7f6 !important;
}

.ant-tabs-tab-active {
  background-color: rgb(103, 58, 183) !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.input-new-detail-category {
  margin-top: 10px !important;
}

.btn-add-detail-category {
  color: #fff !important;
}

.bmi-input {
  background-color: #fff !important;
  color: #000 !important;
  width: 150px !important;
}

.physical-category {
  width: 150px !important;
}

//Employee info section
.employee-info-container {
  padding: 10px;
  margin-bottom: 10px;

  .edit-info-icon {
    margin-left: 10px;
    cursor: pointer;
  }

  .current-input-quantity .ant-descriptions-item-content {
    display: -webkit-inline-box;
  }

  .current-input-quantity .current-employee-input-quantity {
    color: #4aa5ff;
    font-weight: bold;
  }
}

.health-classification-ddl {
  height: 7px;
  width: 100px;
}

.hide-option {
  display: none !important;
}

// .input-category-header {
//   font-weight: bold;
// }
